<template>
  <content-wrapper :title="labels.LBL_CLIENTS" back>
    <template #actions>
      <el-button size="small" type="success" @click="submitForm('clientForm')">
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_SAVE }}
      </el-button>
    </template>

    <el-form
      v-loading="loading"
      :rules="rules"
      ref="clientForm"
      :model="client"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item prop="name" :label="labels.LBL_NAME">
            <el-input v-model="client.name"></el-input>
          </el-form-item>

          <el-form-item :label="labels.LBL_DESCRIPTION">
            <el-input
              type="textarea"
              :rows="4"
              v-model="client.description"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8" :offset="2">
          <el-form-item prop="logo" :label="labels.LBL_LOGO">
            <upload-image v-model="client.logo" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </content-wrapper>
</template>

<script>
  import { labels, rules } from "@/common";
  import { mapState } from "vuex";

  import ContentWrapper from "@/components/layouts/AdminContentWrapper.vue";
  import UploadImage from "@/components/UploadImage.vue";

  export default {
    name: "AddCompany",

    components: {
      ContentWrapper,
      UploadImage,
    },

    data() {
      return {
        labels,
        rules,

        client: {
          name: "",
          logo: "",
          description: "",
        },
      };
    },

    computed: {
      ...mapState({ loading: (state) => state.isBusy }),
    },

    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch("clients/createClient", this.client);
          }
        });
      },
    },
  };
</script>

<style></style>
